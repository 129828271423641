$marginNavigator: calc(0.3vw + 0.3em);

body {
    background-color: #fff;

    img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

#outer-main {
    z-index: -1;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(177, 211, 231, 1) 100%);
    height: 100vh;
    width: 100%;
}

#mainContainer {
    position: absolute;
    max-width: 700px;
    left: 50%;
    top:calc(3em + 3vw);
   
    transform: translate(-50%);

  
}

#mainFrontView {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: calc(0.5em + 0.5vw);
    div {
        display: block;
    }
}

#subViews {
  
    display: grid;
    grid-template-columns: auto auto;
    gap: calc(0.2em + 0.2vw);

    div {
        display: block;

        .sub-headline {
            font-size: calc(0.85vw + 0.85em);
            font-weight: bold;
        }

        @media (max-width: 1080px) {
            .sub-headline {
                font-size: calc(0.75vw + 0.75em);
            }
        }

        @media (max-width: 840px) {
            .sub-headline {
                font-size: calc(0.6vw + 0.6em);
            }
        }

        .sub-text-getintouch {
            color: #fff;
            font-size: calc(0.5vw + 0.5em);
            height: 55%;
        }

        .sub-text-what {
            color: #fff;
            font-size: calc(0.5vw + 0.5em);
            height: 85%;
        }

        .sub-text-maintain {
            color: #fff;
            font-size: calc(0.5vw + 0.5em);
            height: 85%;
        }


        #bottom {
            float: right;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.1rem;
            padding-bottom: 0.2rem;
            color: white;
            background-color: black;
            font-size: calc(0.5vw + 0.5em);
            font-weight: bold;
            border-radius: 6px;
        }
    }

    #link-image {
        display: block;
        margin: 0 auto;

        display: grid;
        grid-template-columns: auto auto auto;
        max-width: 300px;
        gap: 10px;
        height: 36.5%;
        font-size: calc(0.4em + 0.4vw);

        div {
            img {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
               
            }

            
        }

        img {
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

  
}

#mainFrontFooter {
    display: grid;
    grid-template-columns: 70% auto 2%;
    color: #fff;
    font-weight: bold;
    font-size: calc(0.5vw + 0.5em);
    text-align: center;

    #bottom-front {
        float: right;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.1rem;
        padding-bottom: 0.2rem;
        color: white;
        background-color: black;
        font-size: calc(0.4vw + 0.4em);
        font-weight: bold;
        border-radius: 6px;
    }
}

#navigation {
    display: grid;
    margin: 0px;
    grid-template-columns: 10% 10% 10% 50% 15%;
    gap: calc(0.1em + 0.1vw);
    margin-bottom: calc(0.3em + 0.3vw);

    div {
        display: block;

        div {
            display: none;
            border-radius: 25px;
            height: calc(0.3em + 0.3vw);
            margin-left: $marginNavigator;
            margin-right: $marginNavigator;
        }
    }

    @media (max-width: 1380px) {
        display: grid;
        margin: 0px;
        grid-template-columns: 11% 11% 11% 50% 15%;
        margin-bottom: calc(0.3em + 0.3vw);
    }
}

#logo {
    position: relative;

    right: 0px;
    top: 1.5rem;
}
